<template>
    <b-modal  v-model="modalActive" no-close-on-backdrop>
        <template #modal-header>
            <b-tabs-header :tabs="titles" @clickTab="handleClickTab" :activeTab.sync="activeTab" @close="modalActive = false" />
        </template>
        <template #default>
            <div>
                <validation-observer ref="validation">
                    <div v-show="activeTab === 0" :title="$t('config.printer_configs.printer_config')">
                        <b-form-group :label="$t('form_data.name')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                                <b-form-input ref="name" autofocus v-model="addObject.name"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-row>
                            <b-col>
                                <b-form-group :label="$t('config.printer_configs.paper_width_type')">
                                    <template #default>
                                        <validation-provider #default="{ errors }" :name="$t('config.printer_configs.paper_width_type')" rules="required">
                                            <template>
                                                <v-select class="no-wrap-placeholder" v-model="addObject.paper_width_type" appendToBody label="name" :options="paperWidthTypes" :reduce="item => item.id" :clearable="false">
                                                    <template #selected-option="{ name }">
                                                        <div class="custom-selected-option">
                                                            <span>{{ formatSelectedPaperWidthType(name) }}</span>
                                                        </div>
                                                    </template>
                                                </v-select>
                                            </template>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </template>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <fa id="interface" class="toolti" style="left: 6rem" :icon="['fa','info-circle']"/>
                                <b-form-group :label="$t('config.printer_configs.interface')">
                                    <validation-provider #default="{ errors }" :name="$t('config.printer_configs.interface')" :rules="rules">
                                        <b-form-input :placeholder="placeholder" :disabled="addObject.interface_type === 3" name="interface" class="form-control" v-model="addObject.interface"/>
                                        <small class="text-danger">{{errors[0]}}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-form-group :label="$t('config.printer_configs.interface_type')">
                            <validation-provider #default="{ errors }" :name="$t('config.printer_configs.interface_type')" rules="required">
                                <v-select @option:selected="addObject.interface = ''" v-model="addObject.interface_type" appendToBody label="name" :options="getInterfaceTypes" :reduce="item => item.id" :selectable="item => !item.disabled" :clearable="false" @input="changeCashRegister"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('config.printer_configs.add_subcategories')" v-if="$hasTierPermission(Tiers.Premium)">
                            <validation-provider #default="{ errors }" :name="$t('config.printer_configs.add_subcategories')">
                                <v-select multiple  v-model="addObject.subcategories" appendToBody :options="subcategoriesArray" label="name" :reduce="item => item.id">
                                    <template #option="{ name }">
                                        <div>{{ name }}</div>
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group :label="$t('config.printer_configs.add_business_unit')">
                            <validation-provider #default="{ errors }" :name="$t('config.printer_configs.add_business_unit')" rules="required">
                                <v-select  v-model="addObject.business_unit" appendToBody :options="businessUnitsArray" label="name" :reduce="item => item.id">
                                    <template #option="{ name }">
                                        <div>{{ name }}</div>
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div v-show="activeTab === 1" >

                        <b-form-checkbox switch v-model="addObject.print_qr_code_native">
                            {{$t('config.printer_configs.print_qr_code_native')}}
                        </b-form-checkbox>


                        <b-form-group :label="$t('general.cash_register')" v-if="addObject.interface_type !== 0">
                            <v-select  v-model="addObject.cash_register" appendToBody :options="cashRegistersFiltered" label="cash_register_id" :reduce="item => item.id" :selectable="item => item.disabled" >
                                <template #selected-option="{cash_register_id, device_type}">
                                    <span class="mr-2">{{cash_register_id}}</span>
                                    <b-badge variant="secondary" v-if="device_type === CashRegisterType.Unknown">
                                        {{$t('config.display_device_type.no_data')}}
                                    </b-badge>
                                    <b-badge style="background: #1ab7ea;" v-else-if="device_type === CashRegisterType.Windows">
                                        {{$t('config.display_device_type.windows')}}
                                    </b-badge>
                                    <b-badge variant="success" v-else-if="device_type === CashRegisterType.MobileAndroid">
                                        {{$t('config.display_device_type.android_mobile')}}
                                    </b-badge>
                                    <b-badge  style="background: #52bb52;" v-else-if="device_type === CashRegisterType.DesktopAndroid">
                                        {{$t('config.display_device_type.android_desktop')}}
                                    </b-badge>
                                </template>
                                <template #option="{cash_register_id, device_type}">
                                    <span class="mr-2">{{cash_register_id}}</span>
                                    <b-badge variant="secondary" v-if="device_type === CashRegisterType.Unknown">
                                        {{$t('config.display_device_type.no_data')}}
                                    </b-badge>
                                    <b-badge style="background: #1ab7ea;" v-else-if="device_type === CashRegisterType.Windows">
                                        {{$t('config.display_device_type.windows')}}
                                    </b-badge>
                                    <b-badge variant="success" v-else-if="device_type === CashRegisterType.MobileAndroid">
                                        {{$t('config.display_device_type.android_mobile')}}
                                    </b-badge>
                                    <b-badge  style="background: #52bb52;" v-else-if="device_type === CashRegisterType.DesktopAndroid">
                                        {{$t('config.display_device_type.android_desktop')}}
                                    </b-badge>
                                </template>
                            </v-select>
                        </b-form-group>
                        <b-form-group :label="$t('config.printer_configs.max_logo_size')">
                            <validation-provider #default="{ errors }" :name="$t('config.printer_configs.max_logo_size')" rules="min_value:0|numeric">
                                <b-form-input ref="width" type="number" v-model.number="addObject.maximum_logo_size"></b-form-input>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group :label="$t('config.font')">
                            <validation-provider #default="{ errors }" :name="$t('config.font')" rules="">
                                <v-select v-model="addObject.font" appendToBody :options="fonts" label="name" :reduce="name => name.id" :clearable="false">
                                    <template #option="{ name }">
                                        <div>{{ name }}</div>
                                    </template>
                                </v-select>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>

                        <!--  <b-form-group :label="$t('config.font_size')">
                <validation-provider #default="{ errors }" :name="$t('config.font_size')" rules="">
                    <v-select v-model="addObject.font_size" appendToBody :options="fontSizes" label="name" :reduce="name => name.value" :clearable="false">
                        <template #option="{ name }">
                            <div>{{ name }}</div>
                        </template>
                    </v-select>
                    <small class="text-danger">{{errors[0]}}</small>
                </validation-provider>
            </b-form-group>-->

                        <div v-if="addObject.subcategories && addObject.subcategories.length > 0 && $hasTierPermission(Tiers.Premium)">
                            <hr/>
                            <div style="font-weight: bold; font-size: 15px;" class="mb-1">
                                {{$t('config.printer_configs.order_printing_config')}}
                            </div>
                            <b-form-checkbox switch v-model="addObject.printed_order_config.company_info">
                                {{$t('config.printer_configs.company_info')}}
                            </b-form-checkbox>
                            <b-form-checkbox switch v-model="addObject.printed_order_config.timestamp">
                                {{$t('config.printer_configs.date_time')}}
                            </b-form-checkbox>
                            <b-form-checkbox switch v-model="addObject.printed_order_config.table">
                                {{$t('config.printer_configs.table')}}
                            </b-form-checkbox>
                            <b-form-checkbox switch v-model="addObject.printed_order_config.employee">
                                {{$t('general.employees')}}
                            </b-form-checkbox>
                            <b-form-checkbox switch v-model="addObject.printed_order_config.price_per_item">
                                {{$t('config.printer_configs.price_per_item')}}
                            </b-form-checkbox>
                            <b-form-checkbox switch v-model="addObject.printed_order_config.total_price">
                                {{$t('config.printer_configs.total_price')}}
                            </b-form-checkbox>

                            <b-form-checkbox switch v-model="addObject.printed_order_config.type_of_order_enabled">
                                {{$t('config.printer_configs.type_of_order')}}
                            </b-form-checkbox>
                            <b-form-input class="mb-1" v-model="addObject.printed_order_config.type_of_order" v-if="addObject.printed_order_config.type_of_order_enabled" :placeholder="$t('config.printer_configs.enter_type_of_order')">
                            </b-form-input>
                            <b-form-checkbox switch v-model="addObject.printed_order_config.bigger_font_for_printed_order">
                                {{ $t('config.printer_configs.bigger_font_for_printed_order') }}
                            </b-form-checkbox>
                            <b-form-checkbox switch v-model="addObject.printed_order_config.seperate_item">
                                {{ $t('config.printer_configs.separate_item') }}
                            </b-form-checkbox>
                            <b-form-checkbox switch v-model="addObject.printed_order_config.beep_sound">
                                {{ $t('config.printer_configs.beep_sound') }}
                            </b-form-checkbox>
                            <validation-provider class="mb-1" #default="{ errors }" :name="$t('config.printer_configs.beep_sound_number')" rules="required" v-if="addObject.printed_order_config.beep_sound">
                                <b-form-input  v-model.number="addObject.printed_order_config.beep_number" rules="required" :placeholder="$t('config.printer_configs.enter_beep_sound_number')">
                                </b-form-input>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>

                            <b-form-group :label="$t('config.cash_registers.status.printer')" class="mt-1" v-if="addObject.printed_order_config.beep_sound">
                                <validation-provider #default="{ errors }" :name="$t('config.cash_registers.status.printer')" rules="required">
                                    <v-select v-model="addObject.printed_order_config.printer_type" appendToBody :options="printerTypes" label="name" :reduce="name => name.id" :clearable="false">
                                        <template #option="{ name }">
                                            <div>{{ name }}</div>
                                        </template>
                                    </v-select>
                                    <small class="text-danger">{{errors[0]}}</small>
                                </validation-provider>
                            </b-form-group>

                        </div>
                    </div>
                </validation-observer>
            </div>
        </template>
        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {
        BButton,
        BFormGroup,
        BFormInput,
        BModal,
        BRow,
        BCol,
        BFormCheckbox,
        BBadge
    } from 'bootstrap-vue'
    import {ValidationProvider, ValidationObserver} from 'vee-validate'
    import {required, ip, usb, numeric} from '@validations'
    import vSelect from 'vue-select'
    import * as Sentry from '@sentry/vue'
    import {Tiers} from '@/libs/enums/Tiers'
    import {CashRegisterType} from '@/libs/enums/CashRegisters'
    import BTabsHeader from '@/views/components/BTabsHeader.vue'
    import {FontSizeNames} from '@/libs/enums/FontSize'
    import {PrinterTypes} from '@/libs/enums/PrinterTypes'
    import {Font} from '@/libs/enums/Font'

    export default {
        components: {
            BTabsHeader,
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            BFormCheckbox,
            BRow,
            BCol,
            BBadge,
            ValidationProvider,
            ValidationObserver,
            vSelect
        },
        props: {
            businessUnitsArray: {
                Type: Array,
                default: []
            },
            subcategoriesArray: {
                Type: Array,
                default: []
            }
        },
        data() {
            return {
                activeTab: 0,
                cashRegistersFiltered: [],
                cashRegisters: [],
                modalActive: false,
                addObject: {
                    name: '',
                    paper_width_type: 0,
                    interface: '',
                    interface_type: 0,
                    business_unit: '',
                    subcategories: [],
                    print_qr_code_native: false,
                    cash_register: '',
                    maximum_logo_size: 0,
                    font: Font.FontA,
                    font_size: 0,
                    printed_order_config: {
                        company_info: false,
                        timestamp: false,
                        table: false,
                        employee: false,
                        price_per_item: false,
                        total_price: false,
                        seperate_item: false,
                        beep_sound: false,
                        beep_number:0,
                        printer_type:0
                    }

                },
                fontSizes: FontSizeNames,
                printerTypes: [
                    {id: PrinterTypes.Rongta, name: 'Rongta'},
                    {id: PrinterTypes.Citizen, name: 'Citizen'}
                ],
                required,
                numeric,
                editing: false,
                ip,
                usb,
                optionsValidator: ['required|ip', 'required|usb', 'required|min:1', ''],
                placeholders: ['192.168.0.10:1234', 'f8e4/12ea/02/45', 'Printer', '']
            }
        },
        methods: {
            formatSelectedPaperWidthType(name) {
                if (name.length > 20) {
                    if (name.charAt(name.length - 1) === ')') {
                        return `${name.substring(0, 16)  }...)`
                    }
                    return `${name.substring(0, 17)  }...`
                }
                return name
            },
            handleClickTab(index) {
                this.activeTab = index
                if (index === 0) {
                    this.focusName()
                }
                if (index === 1) {
                    this.focusWidth()
                }
            },
            async focusName() {
                await this.$nextTick()
                this.$refs.name.focus()
            },
            async focusWidth() {
                await this.$nextTick()
                this.$refs.width.focus()
            },
            getCashRegisters() {
                switch (this.addObject.interface_type) {
                case 1:
                case 2:
                    this.cashRegistersFiltered = this.cashRegisters.map((x) => {
                        x.disabled = (x.device_type === CashRegisterType.Windows || x.device_type === CashRegisterType.Unknown)
                        return x
                    })
                    break
                case 3:
                    this.cashRegistersFiltered = this.cashRegisters.map((x) => {
                        x.disabled = (x.device_type !== CashRegisterType.Windows)
                        return x
                    })
                    break
                default:
                    this.cashRegistersFiltered = this.cashRegisters
                    break
                }
            },
            checkCashRegister() {
                const cr = this.cashRegisters.find(x => x.id === this.addObject.cash_register)
                if (cr === undefined) {
                    return
                }

                switch (this.addObject.interface_type) {
                case 0:
                    this.addObject.cash_register = ''
                    break
                case 1:
                case 2:
                    if (!(cr.device_type === CashRegisterType.Windows || cr.device_type === CashRegisterType.Unknown)) {
                        this.addObject.cash_register = ''
                    }
                    break
                case 3:
                    if (!(cr.device_type !== CashRegisterType.Windows)) {
                        this.addObject.cash_register = ''
                    }
                    break
                }
            },
            changeCashRegister() {
                this.getCashRegisters()
                this.checkCashRegister()
            },
            async loadData() {
                try {
                    const response = await this.$http.get('/api/client/v1/cash_registers/?getAll=true')
                    this.cashRegisters = response.data.items ?? []
                    this.getCashRegisters()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            open(data = null) {
                if (data !== null) {
                    this.addObject = JSON.parse(JSON.stringify(data))
                    this.editing = true

                    if (this.addObject.cash_register === this.$NilObjectId) {
                        this.addObject.cash_register = null
                    }

                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        paper_width_type: 0,
                        interface: '',
                        interface_type: 0,
                        business_unit: '',
                        subcategories: [],
                        print_qr_code_native: false,
                        cash_register: '',
                        maximum_logo_size: 0,
                        font: Font.FontA,
                        font_size: 0,
                        printed_order_config: {
                            company_info: false,
                            timestamp: false,
                            table: false,
                            employee: false,
                            price_per_item: false,
                            total_price: false,
                            seperate_item: false,
                            beep_sound: false,
                            beep_number:0,
                            printer_type:0

                        }
                    }
                }
                this.getCashRegisters()
                this.modalActive = true
                this.activeTab = 0
            },
            async validate() {
                this.$refs.validation.validate().then(async(success) => {
                    //if (success) await this.addImages()
                    if (success) {
                        if (this.editing) await this.edit()
                        else await this.add()
                    } else {
                        this.$printError(this.$t('print.error.on_add'))
                    }
                })
            },
            async add() {
                try {
                    if (this.addObject.maximum_logo_size === '') {
                        this.addObject.maximum_logo_size = 0
                    }

                    if (this.addObject.subcategories.length === 0) {
                        this.addObject.printed_order_config = {
                            company_info: false,
                            timestamp: false,
                            table: false,
                            employee: false,
                            price_per_item: false,
                            total_price: false,
                            beep_sound: false,
                            beep_number:0,
                            printer_type:0
                        }
                    }

                    const response = await this.$http.post('/api/client/v1/printer_config/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        paper_width_type: 0,
                        interface: '',
                        interface_type: 0,
                        business_unit: '',
                        subcategories: [],
                        print_qr_code_native: false,
                        cash_register: '',
                        maximum_logo_size: 0,
                        font_size: 0,
                        font: Font.FontA,
                        printed_order_config: {
                            company_info: false,
                            timestamp: false,
                            table: false,
                            employee: false,
                            price_per_item: false,
                            total_price: false,
                            seperate_item: false,
                            beep_sound: false,
                            beep_number:0,
                            printer_type:0
                        }
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    if (err.response && err.response.data && err.response.data.error === 'cash-register-not-valid') {
                        this.$printWarning(this.$t('print.warning.non_valid_cash_register'))
                        this.$printSuccess(this.$t('print.success.add'))
                    } else {
                        this.$printError(this.$t('print.error.on_add'))
                    }
                }
            },
            async edit() {
                try {
                    if (this.addObject.maximum_logo_size === '') {
                        this.addObject.maximum_logo_size = 0
                    }

                    if (this.addObject.subcategories.length === 0) {
                        this.addObject.printed_order_config = {
                            company_info: false,
                            timestamp: false,
                            table: false,
                            employee: false,
                            price_per_item: false,
                            total_price: false,
                            beep_sound: false,
                            beep_number:0,
                            printer_type:0
                        }
                    }

                    await this.$http.put(`/api/client/v1/printer_config/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        paper_width_type: 0,
                        interface: '',
                        interface_type: 0,
                        business_unit: '',
                        subcategories: [],
                        print_qr_code_native: false,
                        cash_register: '',
                        maximum_logo_size: 0,
                        font: Font.FontA,
                        font_size: 0,
                        printed_order_config: {
                            company_info: false,
                            timestamp: false,
                            table: false,
                            employee: false,
                            price_per_item: false,
                            total_price: false,
                            seperate_item: false,
                            beep_sound: false,
                            beep_number:0,
                            printer_type:0
                        }
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    if (err.response && err.response.data && err.response.data.error === 'cash-register-not-valid') {
                        this.$printWarning(this.$t('print.warning.non_valid_cash_register'))
                    } else {
                        this.$printError(this.$t('print.error.on_edit'))
                    }
                }
            }
        },
        mounted() {
            this.loadData()
        },
        computed: {
            paperWidthTypes() {
                return [
                    {id: 0, name: this.$t('config.printer_configs.width_types.48chars')},
                    {id: 1, name: this.$t('config.printer_configs.width_types.42chars')},
                    {id: 2, name: this.$t('config.printer_configs.width_types.58mm')}
                ]
            },
            interfaceTypes() {
                return [
                    {id: 0, name: this.$t('config.printer_configs.interface_types.tcp_ip')},
                    {id: 1, name: this.$t('config.printer_configs.interface_types.usb')},
                    {id: 2, name: this.$t('config.printer_configs.interface_types.win_print_service')},
                    {id: 3, name: this.$t('config.printer_configs.interface_types.sunmi_inner_printer')}
                ]
            },
            titles() {
                return [
                    { title: this.$t('config.printer_configs.printer_config') },
                    { title: this.$t('config.printer_configs.advanced') }
                ]
            },
            Tiers() {
                return Tiers
            },
            CashRegisterType() {
                return CashRegisterType
            },
            rules() {
                return this.optionsValidator[this.addObject.interface_type]
            },
            placeholder() {
                return this.placeholders[this.addObject.interface_type]
            },
            getInterfaceTypes() {
                let filtered = JSON.parse(JSON.stringify(this.interfaceTypes))
                if (this.addObject.cash_register === '') {
                    return filtered
                }

                const cashRegister = this.cashRegisters.find(x => x.id === this.addObject.cash_register)
                if (!cashRegister) {
                    return filtered
                }

                switch (cashRegister.device_type) {
                case CashRegisterType.Windows:
                    filtered = filtered.map(x => {
                        x.disabled = (x.id === 3)
                        return x
                    })
                    break
                case CashRegisterType.DesktopAndroid:
                    filtered = filtered.map(x => {
                        x.disabled = ((x.id === 1) || (x.id === 2))
                        return x
                    })
                    break
                case CashRegisterType.MobileAndroid:
                    filtered = filtered.map(x => {
                        x.disabled = ((x.id === 1) || (x.id === 2))
                        return x
                    })
                    break
                case CashRegisterType.Unknown:
                    break
                }
                return filtered
            },
            fonts() {
                return [
                    {id: Font.FontA, name: 'Font A'},
                    {id: Font.FontB, name: 'Font B'}
                ]
            }
        }
    }
</script>

<style scoped>
.toolti {
  position: absolute;
  margin-top: 0.2rem;
  left: 10rem;
}

.no-wrap-placeholder {
min-width: 220px;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
